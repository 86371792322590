import './App.css';

function App() {
  return (
    <div className="main-frame">

    
    <div className="inner-frame">
      
    </div>
    </div>
  );
}

export default App;
